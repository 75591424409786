@import "../constants.css";

#root {
  overflow-x: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background_color);
  overflow-x: hidden;
}

/* div {
  text-align: center;
} */

.footer-background {
  background-color: black;
  height: 49px;
  width: 100%;
  padding: 0 10px;
}

.footer-text {
  height: 49px;
  width: 100%;
}

.max-width-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100vw;
}

.max-width-inner {
  max-width: 1152px;
  width: 100%;
}

.grayscale {
  filter: grayscale(100%);
}

.drop-shadow {
  box-shadow: 0 8px 8px 0 var(--background_color),
    0 6px 20px 0 var(--background_color);
}
