.roboto {
  font-family: "Roboto";
  font-style: normal;
}
.satisfy {
  font-family: "Satisfy";
  font-style: normal;
}

.bold {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.font-size-48 {
  font-size: 48px;
}

.font-size-38 {
  font-size: 38px;
}

.font-size-27 {
  font-size: 27px;
}
.font-size-22 {
  font-size: 22px;
}

.font-color-white {
  color: white;
}
.font-color-p-gray {
  color: var(--paragraph_grey);
}

.font-center {
  text-align: center;
}
