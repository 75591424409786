.accomplishments-background {
  background-image: url("../../resources/images/accomplishments-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 950px;
  height: 100%;
  width: 100%;
  padding: 40px 0;
}

.accomplishment-cards-container {
  margin: 50px 0px;
  width: 100%;
  flex-wrap: wrap;
}

.accomplishment-card {
  width: 282px;
  height: 322px;
  background: #f1f1f1;
  margin: 25px;
}
