.img-default {
    object-fit: contain;
    object-position: center center;
  }
  .img-cover {
    object-fit: cover;
  }
  .img-contain {
    object-fit: contain;
  }
  
  .img-center {
    object-position: center center;
  }
  
  .img-left {
    object-position: left center;
  }
  .img-left {
    object-position: right center;
  }
  
  .img-bottom {
    object-position: center bottom;
  }
  .img-top {
    object-position: center top;
  }
  
  .img-loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .img-drop-shadow{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
  }


  /* POSITIONING */

  .image-container{
    position: relative;
  }

  .image-text-centered {
    color: white;
    text-shadow: 2px 2px black;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }