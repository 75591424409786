.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-align {
  display: flex;
  align-items: center;
}
.flex-justify {
  display: flex;
  justify-content: center;
}

.flex-space-even {
  display: flex;
  justify-content: space-evenly;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-grow-height {
  height: 100%;
}
