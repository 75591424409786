.favorite-things-buffer {
  margin-top: 40px;
}

.ft-cards-container {
  width: 100%;
}

.ft-card {
  padding: 0 40px;
  margin: 18px 0;
}

.ft-card.left {
  justify-content: flex-start;
  text-align: left;
  flex-wrap: wrap;
}

.ft-card.right {
  justify-content: flex-end;
  text-align: right;
  flex-wrap: wrap-reverse;
}

.ft-card-list {
  margin: 5px 0;
}

.ft-card-link {
  cursor: pointer;
  line-height: 25px;
  padding: 4px 0;
  color: var(--paragraph_grey);
  text-decoration: none;
}
.ft-card-link:hover {
  color: var(--accent_blue);
}
